import { Testimonial } from "../../components/Testimonials/testimonial-interface";

import SantiagoFoto from "../../images/santiafo-foto.jpg";
import JosephFoto from "../../images/joseph-foto.jpg";

const MentalHealthTestimonials: Testimonial[] = [
  {
    image: "",
    name: "Anonimo",
    occupation: "Desarrollador de software",
    testimonial:
      "Uno se sumerge en el trabajo. El nivel de estrés los lleva a tener colapsos nerviosos. Al no socializar te aumenta el nivel de estrés. Las empresas te hacen seguimiento en la parte técnica pero no en la parte psicológica. ",
  },
  {
    image: JosephFoto,
    name: "Joseph Florez",
    webSite: "https://www.youtube.com/@josephflrz",
    webSiteName: "Canal de Youtube",
    occupation: "Psicólogo - Magister en Psicología de la salud",
    testimonial:
      "Estudios recientes demuestran que los profesionales del área de las telecomunicaciones, especialmente mujeres, pueden desarrollar síntomas de ansiedad y depresión, debido a la baja interacción social, a la carga laboral habitual y al estrés ocupacional. De no ser tratados a tiempo o bajo protocolos adecuados, los síntomas pueden empeorar. Desde la psicología de la salud se recomienda alimentación adecuada, buena ingesta de agua diaria, sueño reparador de 8 horas por noche, y actividad física de alto impacto que lleve a alta ingesta de oxígeno y a controlar los síntomas mencionados.",
    video:
      "https://mindtechstorageaccount.blob.core.windows.net/resources/joseph-video-salud-mental.mp4",
  },
  {
    webSite: "https://sahydo.com/",
    webSiteName: "sahydo.com",
    image: SantiagoFoto,
    name: "Santiago Hyun Dorado",
    occupation: "Ingeniero de sistemas",
    testimonial:
      "Al comprender cómo funciona el mundo a través de las ciencias de la computación, uno llega a fusionarse con su computadora, lo que puede ser una experiencia inmersiva y gratificante. Sin embargo, esta misma inmersión puede llevar al aislamiento social, dejándote en ocasiones con una sensación de soledad y la falta de un propósito que te complemente como ser humano.",
  },
];
export default MentalHealthTestimonials;
