import { Fab } from "@mui/material";

interface IFloatingButtonProps {
  icon: any;
  color: string;
}
export default function FloatingButton(props: IFloatingButtonProps) {
  return (
    <>
      <Fab
        sx={{
          position: "fixed",
          bottom: "10%",
          right: "10%",
          transition: "all 300ms ease 0ms",
          zindex: "99",
        }}
        className="boton-flotante"
        color="primary"
        aria-label="home"
      >
        {props.icon}
      </Fab>
    </>
  );
}
