import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Topic } from "./card-interface";

export default function PrincipalCard(tema: Topic) {
  return (
    <NavLink to={tema.route}>
      <Card sx={{ maxWidth: 375, height: 375 }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="160"
            image={tema.image}
            alt="img"
          />
          <CardContent sx={{ height: 220 }}>
            <Typography gutterBottom variant="h5" component="div">
              {tema.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {tema.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </NavLink>
  );
}
