import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import MuiChip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";

import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";

import mentalHealthImg from "../../images/MentalHealth/SaludMental.jpg";
import autoHealthImg from "../../images/MentalHealth/Autocuidado.jpg";
import emotionsImg from "../../images/MentalHealth/Emotions.jpg";
const items = [
  {
    icon: <PsychologyAltIcon />,
    title: "¿Qué es la salud mental?",
    descriptionImage:
      "La salud mental es cómo te sientes, piensas y manejas tus emociones. Es importante para tu bienestar general y afecta cómo te relacionas con los demás, cómo enfrentas el estrés y cómo tomas decisiones. Cuando estás bien mentalmente, te sientes equilibrado y capaz de manejar los desafíos de la vida. Si tienes problemas en tu salud mental, como ansiedad o tristeza intensa, puede ser más difícil llevar una vida feliz y saludable. Cuidar tu salud mental es tan importante como cuidar tu salud física.",
    description:
      "La salud mental es cómo te sientes, piensas y manejas tus emociones. Es importante para tu bienestar general y afecta cómo te relacionas con los demás, cómo enfrentas el estrés y cómo tomas decisiones. Cuando estás bien mentalmente, te sientes equilibrado y capaz de manejar los desafíos de la vida. Si tienes problemas en tu salud mental, como ansiedad o tristeza intensa, puede ser más difícil llevar una vida feliz y saludable. Cuidar tu salud mental es tan importante como cuidar tu salud física.",
    imageLight: mentalHealthImg,
    imageDark: mentalHealthImg,
  },
  {
    icon: <SelfImprovementIcon />,
    title: "Autocuidado",
    descriptionImage:
      "Podemos llevar una vida saludable incorporando diversas prácticas beneficiosas. Esto incluye realizar actividad física de manera regular para mantenernos en forma, seguir una alimentación equilibrada y nutritiva que nos proporcione los nutrientes necesarios, y asegurarnos de descansar adecuadamente. Dormir las horas recomendadas es esencial para la recuperación del cuerpo y la mente.",
    description:
      "Tomarte el tiempo para cuidar de ti mismo y de tu bienestar. Esto incluye hacer cosas que te hacen sentir bien, como descansar, comer saludable, hacer ejercicio, y hacer actividades que disfrutas. También puede significar pedir ayuda cuando la necesitas o darte un tiempo para relajarte. ",
    imageLight: autoHealthImg,
    imageDark: autoHealthImg,
  },
  {
    icon: <FaceRetouchingNaturalIcon />,
    title: "Manejo y gestión de emociones",
    descriptionImage:
      "La inteligencia emocional y el autoconocimiento son claves para asegurar que nuestros sentimientos y acciones estén en armonía. Al cultivar estas habilidades, no solo mejoramos nuestra capacidad para enfrentar los desafíos de la vida, sino que también logramos una mayor coherencia y autenticidad en nuestra manera de vivir y relacionarnos con los demás.",
    description:
      "Manejar y gestionar tus emociones es saber cómo entender y controlar cómo te sientes. Es aprender a identificar lo que sientes, como tristeza o enojo, y encontrar formas saludables de manejar esas emociones. Por ejemplo, en lugar de enojarte y gritar, podrías tomarte un momento para calmarte y pensar en lo que realmente te preocupa. La idea es no dejar que tus emociones te controlen, sino usar estrategias para manejarlas de manera que te ayuden a sentirte mejor y a resolver problemas.",
    imageLight: emotionsImg,
    imageDark: emotionsImg,
  },
];

interface ChipProps {
  selected?: boolean;
}

const Chip = styled(MuiChip)<ChipProps>(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          "linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))",
        color: "hsl(0, 0%, 100%)",
        borderColor: theme.palette.primary.light,
        "& .MuiChip-label": {
          color: "hsl(0, 0%, 100%)",
        },
        ...theme.applyStyles("dark", {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));

interface MobileLayoutProps {
  selectedItemIndex: number;
  handleItemClick: (index: number) => void;
  selectedFeature: (typeof items)[0];
}

export function MobileLayout({
  selectedItemIndex,
  handleItemClick,
  selectedFeature,
}: MobileLayoutProps) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "none" },
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          overflow: "auto",
          flexDirection: "column",
        }}
      >
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Box>
      <Card variant="outlined">
        <Box
          sx={(theme) => ({
            mb: 2,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: 280,
            backgroundImage: "var(--items-imageLight)",
            ...theme.applyStyles("dark", {
              backgroundImage: "var(--items-imageDark)",
            }),
          })}
          style={
            items[selectedItemIndex]
              ? ({
                  "--items-imageLight": `url(${items[selectedItemIndex].imageLight})`,
                  "--items-imageDark": `url(${items[selectedItemIndex].imageDark})`,
                } as any)
              : {}
          }
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: "text.primary", fontWeight: "medium" }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

export default function MentalHealthItems() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row-reverse" },
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
          gap: 2,
          height: "100%",
        }}
      >
        {items.map(({ icon, title, description }, index) => (
          <Box
            key={index}
            component={Button}
            onClick={() => handleItemClick(index)}
            sx={[
              (theme) => ({
                p: 2,
                height: "100%",
                width: "100%",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }),
              selectedItemIndex === index && {
                backgroundColor: "action.selected",
              },
            ]}
          >
            <Box
              sx={[
                {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  gap: 1,
                  textAlign: "left",
                  textTransform: "none",
                  color: "text.secondary",
                },
                selectedItemIndex === index && {
                  color: "text.primary",
                },
              ]}
            >
              {icon}
              <Typography variant="h6">{title}</Typography>
              <Typography variant="body2">{description}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <MobileLayout
        selectedItemIndex={selectedItemIndex}
        handleItemClick={handleItemClick}
        selectedFeature={selectedFeature}
      />

      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          width: { xs: "100%", md: "100%" },
          height: "var(--items-image-height)",
        }}
      >
        {/* Esta es la tarjeta */}
        <Card variant="outlined" sx={{ width: "100%" }}>
          <Box
            sx={(theme) => ({
              mb: 2,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: 280,
              backgroundImage: "var(--items-imageLight)",
              ...theme.applyStyles("dark", {
                backgroundImage: "var(--items-imageDark)",
              }),
            })}
            style={
              items[selectedItemIndex]
                ? ({
                    "--items-imageLight": `url(${items[selectedItemIndex].imageLight})`,
                    "--items-imageDark": `url(${items[selectedItemIndex].imageDark})`,
                  } as any)
                : {}
            }
          />
          <Box sx={{ px: 2, pb: 2 }}>
            <Typography
              gutterBottom
              sx={{ color: "text.primary", fontWeight: "medium" }}
            ></Typography>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", mb: 1.5 }}
            >
              {" "}
              {selectedFeature.descriptionImage}
            </Typography>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
