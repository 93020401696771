import Carousel from "react-material-ui-carousel";
import { Comic } from "./comic-interface";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import "./Carousel.css";
import { useState } from "react";
import React from "react";

interface CarouselComponentProps {
  comics: Comic[];
}

export interface InfoPopupInterface {
  title: string;
  content: string;
  children: ChildrenPopUpInterface[];
}

interface ChildrenPopUpInterface {
  subtittle: string;
  content: string;
}
export default function CarouselComponent(props: CarouselComponentProps) {
  const { comics } = props;
  const [open, setOpen] = useState(false);
  const [infoPopup, setInfoPopup] = useState<InfoPopupInterface>();

  const handleClickOpen = (content: any) => {
    setInfoPopup(content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <Typography variant="h4" gutterBottom>
            {infoPopup?.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            ref={descriptionElementRef}
            tabIndex={-1}
          ></DialogContentText>
          <Typography variant="h6" gutterBottom>
            {infoPopup?.content}
          </Typography>
          <hr />
          {infoPopup?.children.map((child) => {
            return (
              <>
                <Typography variant="h6" gutterBottom>
                  <strong>{child.subtittle}: </strong>
                  {child.content}
                </Typography>
              </>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Carousel
        sx={{
          marginLeft: { xs: "0px", sm: "0px", md: 10 },
          marginRight: { xs: "0px", sm: "0px", md: 10 },

          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
        indicators={false}
        navButtonsAlwaysVisible={true}
        autoPlay={false}
        animation="slide"
      >
        {comics.map((item, i) => (
          <>
            <Box
              sx={{
                height: {
                  xs: "400px",
                  sm: "300px",
                  md: "400px",
                  lg: "400px",
                  xl: "600px",
                },
              }}
            >
              <img className="carousel-img" src={item.image} alt={item.image} />
            </Box>
            <Button
              size="medium"
              variant="contained"
              onClick={() => handleClickOpen(item?.content)}
            >
              {item.name}
            </Button>
          </>
        ))}
      </Carousel>
    </>
  );
}
