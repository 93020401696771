import MentalHealthTestimonials from "./data-experiences";
import Testimonials from "../../components/Testimonials/Testiminials";
import CarouselComponent from "../../components/Carousel/Carousel";

import MentalHealthItems from "../../components/MentalHealthItems/MentalHealthItems";
import { NavLink } from "react-router-dom";
import { Container, SvgIcon, SvgIconProps, Typography } from "@mui/material";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import { DataCommicts } from "./data-comics";
import { Comic } from "../../components/Carousel/comic-interface";

export default function MentaHealth() {
  function HomeIcon(props: SvgIconProps) {
    return (
      <SvgIcon {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </SvgIcon>
    );
  }
  const comics: Comic[] = DataCommicts;
  return (
    <>
      <NavLink to={"/"}>
        <FloatingButton
          icon={<HomeIcon fontSize="large" />}
          color={"primary"}
        ></FloatingButton>
      </NavLink>
      <Container sx={{ mb: 3 }}>
        <Typography variant="h3" sx={{ py: 2 }}>
          Salud Mental
        </Typography>
      </Container>

      <Container sx={{ my: 2 }}>
        <MentalHealthItems></MentalHealthItems>
      </Container>
      <CarouselComponent comics={comics}></CarouselComponent>
      <Testimonials
        title="Experiencias y estudios de caso"
        data={MentalHealthTestimonials}
      ></Testimonials>
    </>
  );
}
