import { Button, SvgIcon, SvgIconProps, Typography } from "@mui/material";
import AccordionUsage from "../../components/AccordionUsage/AccordionUsage";
import { NavLink } from "react-router-dom";
import subtemas from "./data";
import Testimonials from "../../components/Testimonials/Testiminials";
import HumanTestimonials from "./data-experiences";
import "../stylepages.css";
import FloatingButton from "../../components/FloatingButton/FloatingButton";

export default function Humans() {
  function HomeIcon(props: SvgIconProps) {
    return (
      <SvgIcon {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </SvgIcon>
    );
  }

  return (
    <>
      <NavLink to={"/"}>
        <FloatingButton
          icon={<HomeIcon fontSize="large" />}
          color={"primary"}
        ></FloatingButton>
      </NavLink>
      <Typography variant="h3" sx={{ py: 2 }}>
        Habilidades Interpersonales
      </Typography>
      <div className="container-accordions">
        <div className="accordion-usage">
          <AccordionUsage accordions={subtemas}></AccordionUsage>{" "}
        </div>
      </div>
      <Testimonials
        title="Experiencias y estudios de caso"
        data={HumanTestimonials}
      ></Testimonials>
    </>
  );
}
