import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  Slide,
} from "@mui/material";
import { useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import BasicCard from "../BasicCard/BasicCard";
import BasicRating from "../BasicRating/BasicRating";
import "./AccordionUsage.css";
import {
  AccordionInterface,
  Child,
  DataOutputAccordion,
  RatingDictionary,
} from "./AccortionInterfaces";

interface AccordionUsageProps {
  accordions: AccordionInterface[];
  onSendData?: (data: DataOutputAccordion) => void;
}

const myDictRating: RatingDictionary = {};

export default function AccordionUsage(props: AccordionUsageProps) {
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState<Child[]>([]);
  const [expanded, setExpanded] = useState<string>("");
  const [average, setAverage] = useState<number>(0);
  const [textValue, settextValue] = useState<string>("");

  const handleClickOpen = (children?: Child[]) => {
    if (children) {
      setChildren(children);
      setOpen(true);
    }
  };

  const handleSaveAverage = (rating: number, name: string) => {
    myDictRating[name] = rating;
    const size = Object.entries(myDictRating).length;
    const values = Object.values(myDictRating);
    const sum = values.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setAverage(sum / size);
  };

  const handleOutput = () => {
    if (props.onSendData)
      props.onSendData({ content: textValue, rating: myDictRating });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <div>
      <div>
        {open && (
          <React.Fragment>
            <Dialog
              fullScreen
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent
                style={{
                  wordBreak: "break-word",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  alignContent: "center",
                }}
              >
                <Grid container gap={{ xs: 2 }} justifyContent={"space-around"}>
                  {children?.map((child) => {
                    return (
                      <BasicCard key={child.title} child={child}></BasicCard>
                    );
                  })}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cerrar</Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )}
      </div>

      {props.accordions.map((acc) => {
        return (
          <Accordion
            sx={{ margin: 3, paddingBottom: 2 }}
            key={acc.title}
            expanded={expanded === acc.title}
            onChange={() => {
              setExpanded(acc.title === expanded ? "" : acc.title);
            }}
          >
            <AccordionSummary
              sx={{
                fontSize: 24,
                fontWeight: 500,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
            >
              <div className="rating-subtopic">
                {acc.title}

                <BasicRating
                  onSendData={(x) => handleSaveAverage(x, acc.title)}
                ></BasicRating>
              </div>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left", fontSize: 18 }}>
              {acc.content}
            </AccordionDetails>

            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              {acc.subtopics?.map((sub) => {
                return (
                  <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    onClick={() => handleClickOpen(sub?.children)}
                    key={sub.title}
                  >
                    {sub.title}
                  </Fab>
                );
              })}
            </Box>
          </Accordion>
        );
      })}
      {/* <BasicRating readonly defaultValue={average}></BasicRating> */}
      {/* <div className="aporte">
        <TextField
          sx={{ width: { xs: 200, sm: 500 } }}
          id="filled-textarea"
          label="Haz tu aporte"
          placeholder="..."
          multiline
          variant="filled"
          value={textValue}
          onChange={(x) => settextValue(x.target.value)}
        />
        <Button
          variant="outlined"
          endIcon={<SendIcon />}
          disabled={textValue === ""}
          onClick={handleOutput}
        >
          Send
        </Button>
      </div> */}
    </div>
  );
}
