import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

interface BasicRatingProps {
  readonly?: boolean;
  defaultValue?: number;
  onSendData?: (data: number) => void;
}

export default function BasicRating(props: BasicRatingProps) {
  const [value, setValue] = React.useState<number | null>(0);
  const handleOnChange = (event: number) => {
    setValue(event);
    if (props.onSendData) {
      props.onSendData(event);
    }
  };

  return (
    <Box
      sx={{
        "& > legend": { mt: 0 },
      }}
    >
      <Rating
        readOnly={props.readonly}
        value={props.readonly ? props.defaultValue : value}
        onChange={(event, newValue) => {
          if (newValue) {
            handleOnChange(newValue);
          }
        }}
      />
      {/* <Typography component="legend">Read only</Typography>
      <Rating name="read-only" value={value} readOnly />
      <Typography component="legend">Disabled</Typography>
      <Rating name="disabled" value={value} disabled />
      <Typography component="legend">No rating given</Typography>
      <Rating name="no-value" value={null} /> */}
    </Box>
  );
}
