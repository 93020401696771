import JavaScriptLogo from "../../images/JavaScript-logo.png";
import CSharpLogo from "../../images/Csharp-logo.png";
import GoLogo from "../../images/Go-logo.png";
import JavaLogo from "../../images/Java-logo.png";
import PythonLogo from "../../images/Python-logo.png";
import TypeScriptLogo from "../../images/Typescript-logo.png";

import WebLogo from "../../images/Web.png";
import MovilLogo from "../../images/movil-logo.jpg";
import NubeLogo from "../../images/Nube-logo.png";
import DockerLogo from "../../images/Docker-logo.png";
import KubernetesLogo from "../../images/Kubernetes-logo.jpg";
import JenkinsLogo from "../../images/Jenkins-logo.jpg";
import GitlabLogo from "../../images/Gitlab-logo.png";
import GithubLogo from "../../images/GitHub-logo.png";
import IALogo from "../../images/AI-logo.webp";
import BlockchainLogo from "../../images/Blockchain-logo.png";

import mysqlLogo from "../../images/mysql-logo.png";
import postgresLogo from "../../images/postgres-logo.png";
import oracleLogo from "../../images/oracle-logo.png";
import mongoLogo from "../../images/mongo-logo.png";
import dynamoLogo from "../../images/DynamoDB-logo.png";
import cassandraLogo from "../../images/apachecassandra-logo.png";

import solidLogo from "../../images/solid-logo.png";
import cleancodeLogo from "../../images/cleancode-logo.png";
import { AccordionInterface } from "../../components/AccordionUsage/AccortionInterfaces";

import funcionalidadLogo from "../../images/funcionalidad-logo.png";
import usabilidadLogo from "../../images/Usabilidad-logo.png";
import rendimientoLogo from "../../images/Rendimiento-logo.jpg";
import confiabilidadLogo from "../../images/Confiabilidad-logo.png";
import mantenibilidadLogo from "../../images/mantenibilidad-logo.webp";
import portabilidadLogo from "../../images/Portabilidad-logo.jpg";
import patronesLogo from "../../images/patrones.png";

import ytLogo from "../../images/Youtube-logo.png";
import desLatamLogo from "../../images/desafioLatam-logo.png";
import chatGPTLogo from "../../images/chatgpt-logo.png";
import duolingoLogo from "../../images/duolingo-gift.gif";

const subtemas: AccordionInterface[] = [
  {
    subtopics: [
      {
        title: "lenguajes de programación relevantes",
        children: [
          {
            title: "JavaScript",
            description1:
              "Desarrollo web (front-end y back-end), aplicaciones móviles y de escritorio.",
            description2: "Frameworks: React, Angular, Vue.js, Node.js.",
            image: JavaScriptLogo,
          },
          {
            title: "Python",
            description1:
              "Desarrollo web, ciencia de datos, inteligencia artificial, automatización, scripting.",
            description2:
              "Frameworks: Django, Flask, Pandas, TensorFlow, PyTorch.",
            image: PythonLogo,
          },
          {
            title: "Java",
            description1:
              "Aplicaciones empresariales, desarrollo Android, sistemas distribuidos.",
            description2: "Frameworks: Spring, Hibernate, JavaFX.",
            image: JavaLogo,
          },
          {
            title: "C#",
            description1:
              "Desarrollo de aplicaciones de escritorio (Windows), desarrollo de juegos (Unity), aplicaciones web.",
            description2: "Frameworks: .NET, ASP.NET, Xamarin.",
            image: CSharpLogo,
          },
          {
            title: "TypeScript",
            description1:
              "Desarrollo web, especialmente en proyectos grandes con JavaScript.",
            description2: "Frameworks: Angular, React (con TypeScript).",
            image: TypeScriptLogo,
          },
          {
            title: "Go (Golang)",
            description1:
              "Desarrollo de sistemas distribuidos, servicios web de alto rendimiento.",
            description2: "Frameworks: Gin, Echo.",
            image: GoLogo,
          },
        ],
      },
      {
        title: "Plataformas relevantes",
        children: [
          {
            title: "Desarrollo Web",
            description1: "Front-end: React, Angular, Vue.js",
            description2: "Back-end: Node.js, Django, Flask, Ruby on Rails",
            image: WebLogo,
          },
          {
            title: "Desarrollo Móvil",
            description1: "Nativo: Android (Java/Kotlin), iOS (Swift)",
            description2: "Multiplataforma: Flutter, React Native, Xamarin",
            image: MovilLogo,
          },
          {
            title: "Plataformas en la Nube",
            description1:
              "Proveedores: AWS, Microsoft Azure, Google Cloud Platform",
            description2:
              "Servicios: Computación en la nube, bases de datos, servicios de IA y aprendizaje automático, DevOps",
            image: NubeLogo,
          },
        ],
      },
      {
        title: "Contenedores y Orquestación",
        children: [
          {
            title: "Docker",
            description1: "Para crear y gestionar contenedores",
            image: DockerLogo,
          },
          {
            title: "Kubernetes",
            description1:
              "Para la orquestación de contenedores y despliegue en entornos distribuidos",
            image: KubernetesLogo,
          },
        ],
      },
      {
        title: "DevOps y CI/CD",
        children: [
          {
            title: "Jenkins",
            description1: "Automatización construcción y despliegue",
            image: JenkinsLogo,
          },
          {
            title: "GitLab CI/CD",
            description1: "Facilita la integración y despliegue continuo",
            image: GitlabLogo,
          },
          {
            title: "GitHub Actions",
            description1: "Automatiza tareas, workflows",
            image: GithubLogo,
          },
        ],
      },
      {
        title: "Tendencias Emergentes",
        children: [
          {
            title: " IA y Aprendizaje Automático",
            description1:
              "Conocimiento en tecnologías emergentes como GPT-4/5, modelos de lenguaje, aprendizaje profundo",
            description2:
              "Sistemas que simulan la inteligencia humana para realizar tareas",
            image: IALogo,
            moreResources: true,
            Urlresources:
              "https://www.iberonex.com/tendencias/las-ultimas-tecnologias-emergentes-que-debes-conocer/",
          },
          {
            title: "Blockchain",
            description1:
              "Conocimiento básico de tecnologías blockchain y contratos inteligentes (Ethereum, Solidity)",
            description2:
              "Blockchain es un registro digital descentralizado y seguro donde se almacenan transacciones en bloques encadenados, garantizando transparencia e inmutabilidad",
            image: BlockchainLogo,
          },
        ],
      },
    ],
    title: "Experiencia en múltiples tecnologías",
    content:
      "Un desarrollador integral estaría familiarizado con una amplia gama de tecnologías y lenguajes de programación,  no debe  casarse con un solo lenguaje y debe estar abierto a aprender cualquier otro, mitigando así su propia obsolescencia en el mundo de la programación. En el mundo del desarrollo de software, las tecnologías cambian rápidamente, por ello es importante ser flexibles y adaptarse, estar un paso adelante , darse a la tarea de conocer nuevas herramientas y adaptarse a las tendencias tecnológicas",
  },
  {
    title: "Capacidad para trabajar en diferentes plataformas",
    content:
      "Un desarrollador integral puede trabajar tanto en aplicaciones de escritorio como en aplicaciones web, móviles o en la nube, utilizando las herramientas y tecnologías adecuadas para cada plataforma. Conocer múltiples tecnologías te acerca al rol full stack, lo cual es uno de los principales. En la industria actual, un desarrollador con conocimientos en múltiples plataformas tiene acceso a una amplia variedad de ofertas laborales, las empresas valoran a un desarrollador que puede contribuir desde distintos frentes, dado que pueden aprovechar al máximo sus habilidades y maximizar su talento. Si lo que se desea no es trabajar para una empresa, sino crear la suya propia, conocer de múltiples tecnologías no deja de ser útil, pues esto no va a permitir elegir las herramientas más adecuadas para nuestras tareas",
  },
  {
    title: "Competencia en bases de datos relacionales y no relacionales",
    content:
      "Un desarrollador de software debe tener conocimiento y experiencia en bases de datos relacionales ya que estas  proporcionan las herramientas necesarias para trabajar con datos de manera efectiva, optimizar el rendimiento de las aplicaciones, y desarrollar soluciones escalables y mantenibles. Diseño de bases de datos y optimización de consultas: No solo que una consulta me funcione, sino que sea la más óptima. Hay que buscar siempre la versión más eficiente de una consulta. Conocer el diseño de bases de datos y la optimización de consultas es crucial para desarrollar aplicaciones que sean rápidas, escalables y fáciles de mantener. Esto no solo mejora el rendimiento y la eficiencia, sino que también contribuye a una experiencia de usuario satisfactoria y a un manejo más efectivo de los recursos.",
    subtopics: [
      {
        title: "Bases de Datos Relacionales",
        children: [
          {
            image: mysqlLogo,
            title: "MySQL",
            description1:
              "Sistema de gestión de bases de datos relacional de código abierto",
            description2:
              "Popular por su facilidad de uso, rendimiento y compatibilidad con aplicaciones web, especialmente en entornos de desarrollo y producción de tamaño pequeño a mediano.",
            moreResources: true,
            Urlresources: "https://sqlbolt.com/",
          },
          {
            image: postgresLogo,
            title: "PostgreSQL",
            description1: "Base de datos relacional de código abierto",
            description2:
              "Conocida por su soporte a estándares SQL, extensibilidad y capacidad para manejar datos complejos y consultas avanzadas",
          },
          {
            image: oracleLogo,
            title: "Oracle",
            description1: "Sistema de gestión de bases de datos relacional",
            description2:
              "Conocido por su robustez, escalabilidad y características empresariales, utilizado principalmente en grandes organizaciones.",
          },
        ],
      },
      {
        title: "Bases de Datos NoSQL",
        children: [
          {
            image: mongoLogo,
            title: "MongoDB",
            description1: "Almacenan datos en documentos",
          },
          {
            image: dynamoLogo,
            title: "DynamoDB",
            description1: "Guardan datos en pares clave-valor",
          },
          {
            image: cassandraLogo,
            title: "Apache Cassandra",
            description1: "Almacenan datos en columnas en lugar de filas",
          },
        ],
      },
    ],
  },
  {
    title: "Buenas prácticas de desarrollo",
    content:
      "Adoptar buenas prácticas de desarrollo y seguir principios como SOLID no solo mejora la calidad del código y del software en sí, sino que también facilita la colaboración, el mantenimiento y la evolución del proyecto a lo largo del tiempo",
    subtopics: [
      {
        title: "Clean Code y principios SOLID",
        children: [
          {
            image: patronesLogo,
            title: "Patrones de Diseño",
            description1:
              "Los patrones de diseño son soluciones probadas a problemas comunes que se presentan en el desarrollo de software. Piensa en ellos como recetas o plantillas que te dicen cómo resolver un problema específico de manera eficaz.",
            description2:
              "los patrones de diseño son buenas prácticas y soluciones que han sido validadas por otros programadores para hacer el desarrollo de software más organizado y eficiente.",
            moreResources: true,
            Urlresources: "https://refactoring.guru/es",
          },
          {
            image: cleancodeLogo,
            title: "Clean Code",
            description1:
              "Código bien estructurado, legible y mantenible, fácil de entender y modificar",
            description2:
              "Clean Code busca que el código sea claro, ordenado y fácil de trabajar, tanto para la persona que lo escribe como para cualquier otra que tenga que mantenerlo en el futuro.",
            moreResources: true,
            Urlresources:
              "https://elhacker.info/manuales/Lenguajes%20de%20Programacion/Codigo%20limpio%20-%20Robert%20Cecil%20Martin.pdf",
          },
          {
            image: solidLogo,
            title: "SOLID",
            description1:
              "Conjunto de cinco principios para diseño de software orientado a objetos, que promueven flexibilidad y mantenimiento",
            description2:
              " S (Responsabilidad Única), O (Abierto/Cerrado), L (Sustitución de Liskov), I (Segregación de Interfaces), D (Inversión de Dependencias).",
            moreResources: true,
            Urlresources: "https://refactoring.guru/es",
          },
        ],
      },
      {
        title: "Atributos de calidad",
        children: [
          {
            image: funcionalidadLogo,
            title: "Funcionalidad",
            description1:
              "Qué tan bien el software cumple con los requisitos y realiza las funciones esperadas",
          },
          {
            image: usabilidadLogo,
            title: "Usabilidad",
            description1:
              "Qué tan fácil y eficiente es para los usuarios aprender a usar y operar el software",
          },
          {
            image: rendimientoLogo,
            title: "Rendimiento",
            description1:
              "La rapidez con la que el software responde y procesa las solicitudes, incluyendo tiempo de respuesta y eficiencia de recursos",
          },
          {
            image: confiabilidadLogo,
            title: "Confiabilidad",
            description1:
              "La estabilidad y la capacidad del software para funcionar sin fallos o errores durante su uso",
          },
          {
            image: mantenibilidadLogo,
            title: "Mantenibilidad",
            description1:
              "La facilidad con la que el software puede ser modificado para corregir errores, mejorar características o adaptarse a cambios",
          },
          {
            image: portabilidadLogo,
            title: "Portabilidad",
            description1:
              "La capacidad del software para funcionar en diferentes entornos o sistemas operativos con mínimas modificaciones",
          },
        ],
      },
    ],
  },
  {
    title: "Segundo idioma (Inglés)",
    content:
      "El inglés es crucial para los desarrolladores de software porque la mayoría de la documentación técnica, tutoriales y foros están en este idioma. Facilita el acceso a herramientas y lenguajes de programación, y permite participar en comunidades globales y estar al tanto de las últimas tendencias y mejores prácticas. Además, muchas empresas y oportunidades laborales requieren un buen dominio del inglés, lo que hace que saber el idioma sea una ventaja importante en el mundo del desarrollo de software",
    subtopics: [
      {
        title: "Herramientas",
        children: [
          {
            image: duolingoLogo,
            title: "Duolingo",
            description1:
              "Plataforma de aprendizaje de idiomas más grande del mundo, donde cualquier persona puede aprender otro idioma haciéndose responsable de su propio ritmo",
          },
          {
            image: chatGPTLogo,
            title: "Chat GPT",
            description1:
              "Puedes usar ChatGPT para practicar inglés mediante conversaciones diarias, corrección de textos, aprendizaje de nuevas palabras y frases, ejercicios de gramática, comprensión de lectura, role-playing, discusión de temas de interés, y traducción y comparación de textos",
            description2:
              "''Hola ChatGPT, me gustaría practicar mi inglés contigo. ¿Podríamos tener una conversación en inglés para mejorar mi fluidez?''",
          },
        ],
      },
      {
        title: "Cursos gratuitos",
        children: [
          {
            image: desLatamLogo,
            title: "English for Developers",
            description1:
              "Este curso está diseñado específicamente para aquellos que trabajan o se proyectan en el mundo del desarrollo y la tecnología de la información, abarcando desde lo básico y esencial hasta habilidades comunicativas avanzadas.",
            moreResources: true,
            Urlresources:
              "https://cursos.desafiolatam.com/courses/english-for-developers-it-professionals",
          },
          {
            image: ytLogo,
            title: "English2Me",
            description1:
              "En English2Me encontrarás clases y cursos con gramática, pronunciación, vocabulario y ejercicios interactivos. Además que tendrás a tu disponibilidad material totalmente gratuito.",
            moreResources: true,
            Urlresources: "https://www.youtube.com/@English2Me",
          },
          {
            image: ytLogo,
            title: "Learn English with EnglishClass101.com",
            description1:
              "Start speaking English in minutes with Audio and Video lessons. EnglishClass101.com is an online English language learning website. With us you'll learn to speak, read, and write English. You'll have fun learning with listeners around the world. Get ready to 'wow' your friends, family, teachers and colleagues with the English you'll learn with us!",
            moreResources: true,
            Urlresources: "https://www.youtube.com/@EnglishClass101",
          },
        ],
      },
    ],
  },
];

export default subtemas;
