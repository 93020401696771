import "./App.css";
import MainPage from "./pages/main/main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Programmig from "./pages/programmig/programmig";
import Humans from "./pages/humans/humans";
import MentaHealth from "./pages/mental-health/mental-health";
import {
  Container,
  createTheme,
  CssBaseline,
  PaletteMode,
  ThemeProvider,
} from "@mui/material";
import AppAppBar from "./components/MenuAppBar/MenuAppBar";
import React from "react";
import getLPTheme from "./theme";

function App() {
  const [mode, setMode] = React.useState<PaletteMode>("light");
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <div className="App">
      <ThemeProvider theme={LPtheme}>
        <CssBaseline />
        <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
        <Container sx={{ mt: { xs: "100px", sm: "6%" } }}>
          {" "}
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/programmig" element={<Programmig />} />
              <Route path="/humans" element={<Humans />} />
              <Route path="/mental-health" element={<MentaHealth />} />
            </Routes>
          </BrowserRouter>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default App;
