import { Testimonial } from "../../components/Testimonials/testimonial-interface";

const HumanTestimonials: Testimonial[] = [
  {
    name: "Dr. Jairo Hernández Navas",
    occupation: "Director Fundacion Universitaria Maria Cano",
    testimonial:
      "En el mundo de la ingeniería, mientras que los problemas técnicos suelen tener soluciones bien definidas, el aspecto personal y humano a menudo queda desatendido. Las empresas buscan constantemente incrementar la productividad, a veces sacrificando la dimensión humana del trabajo, lo que puede llevar a una desconexión entre los empleados y sus entornos laborales. Para abordar estos desafíos, es esencial que los ingenieros desarrollen habilidades blandas, que, a diferencia de las competencias técnicas, no se estudian en libros, sino que se practican. La empatía y la cognición social juegan un papel crucial aquí, ya que permiten a los ingenieros responder a la frustración y a los cambios con madurez mental y resiliencia. Una actitud positiva y una predisposición mental proactiva pueden transformar cómo enfrentan los retos y cómo interactúan con los demás. La inteligencia emocional, que implica entender y gestionar las propias emociones, es fundamental para mejorar la interacción y la cooperación en el trabajo. El cuerpo y la mente están intrínsecamente conectados, y entender cómo nuestras emociones afectan nuestro comportamiento y nuestra productividad es clave para un rendimiento óptimo y una convivencia armónica en el entorno laboral",
  },
  {
    name: "Nanci Liliana Cuartas Cardona",
    occupation: "Coordinadora Fundacion Universitaria Maria Cano",
    testimonial:
      "El éxito va más allá de las habilidades técnicas. La comunicación efectiva y asertiva es esencial para relacionarse adecuadamente con los demás, mientras que una actitud positiva y la capacidad de actuar con disposición son cruciales. El liderazgo no se trata solo de ser el jefe, sino de liderar el propio proceso y ser autónomo en el desarrollo profesional. Además, el trabajo en equipo implica saber relacionarse y manejar conflictos de manera constructiva. La gestión de emociones, o inteligencia emocional, es fundamental para manejar cómo nos comportamos en situaciones difíciles. Aunque la personalidad, compuesta por temperamento y carácter, no cambia fácilmente, sí es posible modificar el comportamiento a través de herramientas y estrategias adecuadas.",
  },
  {
    name: "Jhonier Córdoba",
    occupation: "Desarrollador de Software",
    testimonial:
      "Para mi ser desarrollador de software ha sido la oportunidad de adquirir una gran cantidad de conocimientos a la vez que me desenvuelvo en resolver problemas o retos que constantemente me hacen investigar, informarme y aplicar conocimientos. Todo esto ha aportado a mi desarrollo personal y aunque no sea muy común he podido aplicar varios de mis aprendizajes en la vida diarias",
  },
];
export default HumanTestimonials;
