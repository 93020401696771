import { Topic } from "../../components/PrincipalCard/card-interface";
import PrincipalCard from "../../components/PrincipalCard/PrincipalCard";
import "../stylepages.css";
import programmig from "../../images/habilidades-tecnicas.jpg";
import humans from "../../images/habilidades-integrales.jpg";
import mental from "../../images/salud-mental.jpg";
import { Typography } from "@mui/material";

export default function MainPage() {
  const cards: Topic[] = [
    {
      title: "Habilidades Técnicas",
      description:
        "En el mundo del desarrollo de software, las tecnologías cambian rápidamente, por ello es importante ser flexibles y adaptarse",
      image: programmig,
      route: "programmig",
    },
    {
      title: "Habilidades Interpersonales",
      description:
        "Las habilidades blandas y personales complementan las habilidades técnicas de un desarrollador de software, facilitando una colaboración efectiva, una gestión eficiente del tiempo y un ambiente de trabajo positivo. Estas habilidades también contribuyen al desarrollo profesional continuo y al éxito general en el campo.",
      image: humans,
      route: "humans",
    },
    {
      title: "Salud Mental",
      description:
        "Se quiere un experto en tecnología, pero que tenga habilidades de comunicación, colaboración y una mentalidad orientada a la solución y al aprendizaje continuo; todo esto sin dejar de lado un factor tan importante como cuidar su salud mental.",
      image: mental,
      route: "mental-health",
    },
  ];

  return (
    <>
      <Typography variant="h3" sx={{ py: 3 }}>
        Guía para Ser un Desarrollador de Software Integral
      </Typography>
      <div className="contenedor-cartas">
        {cards.map((card) => {
          return (
            <>
              <PrincipalCard
                key={card.title}
                route={card.route}
                title={card.title}
                description={card.description}
                image={card.image}
              ></PrincipalCard>
            </>
          );
        })}
      </div>
    </>
  );
}
