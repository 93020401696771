import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Testimonial } from "./testimonial-interface";
import { Avatar } from "@mui/material";
import "./testimonial.css";

interface TestimonialsProps {
  data: Testimonial[];
  title: string;
}

export default function Testimonials(props: TestimonialsProps) {
  return (
    <Container
      sx={{
        px: 0,
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          {props.title}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {props.data.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex" }}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pr: 2,
                }}
              >
                {testimonial.video && (
                  <video width="100" controls src={testimonial.video}></video>
                )}
                <CardHeader
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <div className="image-webite">
                  {<Avatar alt={testimonial.name} src={testimonial.image} />}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={testimonial.webSite}
                  >
                    {testimonial.webSiteName}
                  </a>
                </div>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
