import { AccordionInterface } from "../../components/AccordionUsage/AccortionInterfaces";

import problemsImg from "../../images/HumanPage/problems.jpg";
import habilitiesImg from "../../images/HumanPage/habilities.jpg";
import codeImg from "../../images/HumanPage/codequality.jpg";
import educationImg from "../../images/HumanPage/continuousEducation.jpg";
import changesImg from "../../images/HumanPage/changes.jpg";

import noVerbalImg from "../../images/HumanPage/noverbal.jpg";
import rechazoImg from "../../images/HumanPage/rechazo.jpg";
import renunciarImg from "../../images/HumanPage/renunciar.jpg";
import opinionImg from "../../images/HumanPage/opinions.jpg";

const subtemas: AccordionInterface[] = [
  {
    title: "Colaboración y trabajo en equipo",
    content:
      "La comunicación es la clave para una colaboración efectiva. Sin una comunicación clara y abierta, los malentendidos y la falta de coordinación pueden llevar a errores y retrasos. Mantener a todos en la misma página y compartir información de manera efectiva asegura que el equipo funcione de manera armoniosa y eficiente, logrando los objetivos del proyecto con éxito",
    subtopics: [
      {
        title: "Ver más",
        children: [
          {
            image: problemsImg,
            title: "Resolución de Problemas",
            description1:
              " El desarrollo de software a menudo implica enfrentar problemas complejos que pueden ser más fácilmente resueltos cuando varios miembros del equipo aportan sus habilidades y perspectivas únicas. Colaborar permite identificar soluciones más eficientes y efectivas",
          },
          {
            image: habilitiesImg,
            title: "Diversidad de Habilidades",
            description1:
              "En un equipo, cada miembro puede tener experiencia en áreas diferentes, como diseño, desarrollo backend, frontend o pruebas. Trabajar en equipo asegura que se combinen todas estas habilidades para crear un producto más completo y robusto",
          },
          {
            image: codeImg,
            title: "Calidad del Código",
            description1:
              "La revisión de código entre compañeros y la colaboración en el desarrollo ayuda a mantener la calidad del código. Las revisiones permiten detectar errores y mejorar el código antes de que llegue a producción",
          },
          {
            image: educationImg,
            title: "Aprendizaje y Crecimiento",
            description1:
              "Trabajar en equipo brinda la oportunidad de aprender de los demás y compartir conocimientos. La colaboración fomenta el crecimiento profesional y personal, ya que los desarrolladores pueden adquirir nuevas habilidades y enfoques",
          },
          {
            image: changesImg,
            title: "Adaptación a Cambios",
            description1:
              "Los proyectos de software a menudo cambian y evolucionan. Un equipo colaborativo puede adaptarse mejor a estos cambios, redistribuyendo tareas y ajustando estrategias según sea necesario",
          },
        ],
      },
    ],
  },
  {
    title: "Liderazgo ",
    content:
      "El líder no es el jefe. Es liderar tu propio proceso y ser autónomo. En el contexto del trabajo en equipo, esto significa que cada miembro del equipo debe ser proactivo y tomar responsabilidad por su parte del trabajo. La capacidad de ser autónomo y tomar decisiones informadas sin necesidad de una supervisión constante es esencial. Un equipo eficiente no solo sigue las directrices del líder, sino que cada miembro contribuye activamente, se autodirige y colabora de manera constructiva para alcanzar los objetivos comunes",
  },
  {
    title: "Aprendizaje continuo y capacidad de adaptación",
    content:
      "Dado que la tecnología está en constante evolución, un desarrollador integral debe estar dispuesto a aprender nuevas herramientas y tecnologías, y adaptarse rápidamente a los cambios en el panorama tecnológico.",
  },
  {
    title: "Comunicación asertiva y efectiva",
    content:
      "la comunicación asertiva y efectiva es clave para el éxito en el desarrollo de software. Permite una mejor colaboración, una resolución más eficiente de problemas, y una gestión adecuada de expectativas y conflictos. Facilita la creación de un entorno de trabajo productivo y profesional, donde las ideas y los problemas se manejan de manera abierta y constructiva",
    subtopics: [
      {
        title: "Ver más",
        children: [
          {
            image: noVerbalImg,
            title: "Comunicación no verbal",
            description1:
              "La comunicación no verbal es crucial porque complementa, refuerza y a veces incluso contrasta el mensaje verbal, la comunicación no verbal es una parte integral de cómo compartimos y percibimos información. Nos ayuda a entender mejor a los demás y a transmitir nuestras intenciones de manera más completa",
          },
          {
            image: rechazoImg,
            title: "Nunca desprestigiar/rechazar",
            description1:
              "Despreciar o rechazar lo que otra persona dice puede ser perjudicial para la comunicación efectiva y las relaciones interpersonales. En lugar de adoptar una actitud despectiva, es más constructivo hacer aportes y contribuciones al diálogo",
            description2:
              "Reconocer que 'Yo no tengo la verdad absoluta' facilita una comunicación más efectiva, respeta la diversidad de opiniones y fomenta un entorno de aprendizaje y colaboración",
          },
          {
            image: renunciarImg,
            title: "Renunciar a tener la razón",
            description1:
              "Permite un diálogo más abierto y constructivo. Cuando nos empeñamos en demostrar que tenemos la razón, a menudo nos volvemos inflexibles y cerrados a las perspectivas de los demás",
            description2:
              " Empeñarse en demostrar que se tiene la razón y cebtrarse exclusivamente en demostrar nuestra propia validez impide escuchar y entender. Una discusión debe ser un intercambio de ideas, no un enfrentamiento",
          },
          {
            image: opinionImg,
            title: "Opiniones",
            description1:
              "Opinar sobre los demás puede ser problemático, especialmente si no es nuestra responsabilidad o si no tenemos un conocimiento completo de la situación",
            description2:
              "Opinar sobre los demás sin tener la responsabilidad o el conocimiento adecuado puede ser perjudicial y contraproducente",
          },
        ],
      },
    ],
  },
];

export default subtemas;
