import { Testimonial } from "../../components/Testimonials/testimonial-interface";

import SantiagoFoto from "../../images/santiafo-foto.jpg";
import JhonFoto from "../../images/Jhon-foto.jpg";

const TechnicTestimonials: Testimonial[] = [
  {
    webSite: "https://sahydo.com/",
    webSiteName: "sahydo.com",
    image: SantiagoFoto,
    name: "Santiago Hyun Dorado",
    occupation: "Ingeniero de sistemas",
    testimonial:
      "La ingeniería de sistemas transformó por completo mi estilo de vida, abriéndome puertas a oportunidades que antes solo podía imaginar. Ha sido un camino desafiante, pero profundamente emocionante, lleno de aprendizaje constante.",
  },
  {
    name: "Daniel Ramirez",
    occupation: "Ingeniero de sistemas",
    testimonial:
      "En la industria, los conocimientos técnicos necesarios varían según el tipo de empresa; por ejemplo, en una startup es crucial tener conocimientos globales debido a su naturaleza dinámica, mientras que en compañías grandes se requieren habilidades tanto específicas como generales. Las empresas abordan las brechas técnicas en función de la industria y factores económicos, pero es fundamental tener un conocimiento técnico mínimo. No obstante, aunque una persona pueda ser técnicamente competente, si carece de habilidades blandas y valores éticos, podría ser excluida de la selección, dado que estos aspectos son igualmente importantes. El mercado reconoce los desafíos tecnológicos y proporciona apoyo y claridad en cuestiones éticas. Además, la salud mental es crucial en esta industria, ya que el estrés es una constante y los ingenieros a menudo enfrentan hábitos de vida poco saludables. Aunque fomentar el apoyo en salud mental dentro de las compañías puede ser difícil, es esencial priorizar el bienestar mental para mantener un entorno laboral saludable.",
  },
  {
    image: JhonFoto,
    name: "Jhon Avecedo",
    occupation: "Desarrollador de software",
    testimonial:
      "He sido ingeniero de sistemas por más de 8 años, y amo lo que hago. Es increíblemente gratificante ver cómo es posible convertir ideas en realidad con tan pocos elementos: solo tu computadora y tu mente creativa. Ser desarrollador es una profesión llena de retos, como la necesidad de mantenerse actualizado constantemente, ser adaptable y tener siempre una mente abierta y creativa, pero las recompensas lo valen. Cuando recién terminé mis estudios, sentía, como muchos, que mis conocimientos y habilidades no eran suficientes. Sin embargo, trabajar junto a personas talentosas, satisfacer mi curiosidad aprendiendo nuevas tecnologías y asumir proyectos desafiantes me ha permitido crecer y llegar a un punto del que estoy muy orgulloso. Un consejo de mi experiencia: disfruta el proceso, mantén la calma y no te alejes de las personas que te rodean, porque las grandes ideas suelen surgir cuando las mentes están en paz. ¡Recuerda, una línea de código a la vez!",
  },
  {
    name: "Jhonier Córdoba",
    occupation: "Desarrollador de Software",
    testimonial:
      "Desde mi experiencia siento que las empresas a un nivel meramente técnico están en búsqueda de un desarrollador que comprenda las tecnologías que se manejan en la empresa, esto implica, según el caso, conocimientos basicos en la nube o desarrollo web, pero más que esto, buscan un entendimiento general de la lógica de programación y una persona dispuesta a aprender ya que a partir de ahí se considera que una persona puede desenvolverse en cualquier lenguaje.",
  },
];
export default TechnicTestimonials;
