import { Comic } from "../../components/Carousel/comic-interface";

import impostorImg from "../../images/Historietas/SindromeImpostor.png";
import pensamientosImg from "../../images/Historietas/PenamientosRecurrentes.png";
import frustracionImg from "../../images/Historietas/Frustracion.png";
import burnoutImg from "../../images/Historietas/Burnout.png";
export const DataCommicts: Comic[] = [
  {
    image: impostorImg,
    name: "Síndrome del impostor",
    content: {
      title: "Síndrome del impostor",
      content:
        "El síndrome del impostor es un fenómeno psicológico en el que una persona duda de sus logros y teme ser descubierta como una fraude, a pesar de evidencias de su competencia. En un desarrollador de software, esto puede manifestarse de varias maneras, como el miedo a que su código sea deficiente, a ser ''descubierto'' como incompetente, o a que sus contribuciones no sean valoradas.",
      children: [
        {
          subtittle: "Reconocer el Problema",
          content:
            "El primer paso es que el desarrollador reconozca que está experimentando el síndrome del impostor. Aceptar que este sentimiento es común y que muchos profesionales lo enfrentan puede ser el primer paso hacia la superación.",
        },
        {
          subtittle: "Crear un Registro de Logros",
          content:
            "Mantener un registro detallado de logros, proyectos exitosos, elogios recibidos y habilidades adquiridas puede ayudar a contrarrestar los pensamientos negativos. Revisar este registro cuando surjan dudas puede ofrecer una perspectiva objetiva de sus capacidades.",
        },
        {
          subtittle: "Considerar Terapia o Coaching",
          content:
            "Hablar con un terapeuta o un coach profesional puede ayudar a abordar las raíces profundas del síndrome del impostor y desarrollar estrategias para manejarlo de manera efectiva.",
        },
      ],
    },
  },
  {
    image: pensamientosImg,
    name: "Pensamientos recurrentes y aislamiento social",
    content: {
      title: "Pensamientos recurrentes y aislamiento social",
      content:
        "Son comunes en todos los ámbitos, y el desarrollo de software no es una excepción. Estos pensamientos pueden ser tanto constructivos como destructivos, influyendo significativamente en la productividad, la satisfacción laboral y la vida social.",
      children: [
        {
          subtittle: "Identificar el Problema:",
          content:
            "El primer paso es reconocer cómo los pensamientos recurrentes están afectando la vida social. Aceptar que estos pensamientos están causando aislamiento y estrés es esencial para abordar el problema.",
        },
        {
          subtittle: "Establecer un equilibrio",
          content:
            "Programar tiempo específico para actividades sociales y mantener un equilibrio entre el trabajo y la vida personal. Establecer límites claros para el trabajo y reservar tiempo para interactuar con amigos y familiares.",
        },
        {
          subtittle: "Considerar Terapia",
          content:
            "Hablar con un terapeuta puede proporcionar herramientas y técnicas para abordar los pensamientos recurrentes y sus efectos en la vida social. La terapia cognitivo-conductual, en particular, puede ser útil para cambiar patrones de pensamiento negativos.",
        },
        {
          subtittle: "Programar Tiempo de Desconexión",
          content:
            "Crear rutinas que incluyan tiempo para desconectar del trabajo, como prácticas de autocuidado y tiempo libre, puede ayudar a reducir la influencia de los pensamientos recurrentes en la vida social.",
        },
      ],
    },
  },
  {
    image: frustracionImg,
    name: "Frustración ténica",
    content: {
      title: "Frustración ténica",
      content:
        "La frustración técnica en un desarrollador de software ocurre cuando se enfrenta a problemas técnicos complejos o imprevistos que no puede resolver de manera inmediata. Esto puede incluir errores difíciles de depurar, tecnologías nuevas que no entiende bien",
      children: [
        {
          subtittle: "Reconocer el Problema",
          content:
            "Reconocer que la frustración técnica es una respuesta normal a problemas difíciles es el primer paso. No hay nada de malo en sentirse frustrado, y aceptar esto puede ayudar a aliviar parte del estrés asociado.",
        },
        {
          subtittle: "Implementar Pausas Efectivas",
          content:
            "Tomar descansos breves y regulares puede ayudar a despejar la mente y reducir el agotamiento. Alejarse del problema durante un tiempo puede ofrecer una nueva perspectiva y ayudar a evitar el estancamiento mental. Alejarse del ordenador y realizar actividades relajantes puede ayudar a despejar la mente.",
        },
        {
          subtittle: "Descomponer Tareas",
          content:
            "Dividir el problema en partes más pequeñas y manejables puede hacer que la resolución sea menos abrumadora. Abordar cada componente por separado puede facilitar el progreso y ayudar a identificar soluciones más fácilmente.",
        },
        {
          subtittle: "Incorporar técnicas de relajación",
          content:
            "Técnicas como la meditación, la respiración profunda, y el ejercicio físico pueden ayudar a reducir el estrés y mejorar la claridad mental.",
        },
      ],
    },
  },
  {
    image: burnoutImg,
    name: "Síndrome del Burnout",
    content: {
      title: "Síndrome del Burnout",
      content:
        "Estado de agotamiento físico, emocional y mental, es cada vez más común entre los desarrolladores de software. Este desgaste puede manifestarse de diversas formas y afectar significativamente su vida tanto profesional como personal.",
      children: [
        {
          subtittle: "Solicitar Tiempo de Descanso",
          content:
            "Si es posible, tomar un tiempo libre para recuperarse física y emocionalmente puede ser fundamental. Un descanso adecuado permite descansar y reflexionar sobre las causas del burnout.",
        },
        {
          subtittle: "Mantener Relaciones Sociales",
          content:
            " Conectar con amigos, familiares y colegas para recibir apoyo emocional y compartir experiencias puede proporcionar una red de apoyo vital.",
        },
        {
          subtittle: "Cuidar la Salud Física y Mental",
          content:
            "Asegurarse de mantener una dieta equilibrada, dormir lo suficiente y participar en actividades que brinden placer y relajación. El autocuidado es crucial para prevenir y superar el burnout.",
        },
      ],
    },
  },
];
