import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import "./BasicCard.css";
import { Child } from "../AccordionUsage/AccortionInterfaces";
import { NavLink } from "react-router-dom";

interface BasicCardProps {
  child: Child;
}

export default function BasicCard(data: BasicCardProps) {
  return (
    <Card sx={{ width: 275 }}>
      <CardContent>
        <div className="head-card">
          <Typography variant="h5" component="div">
            {data.child.title}
          </Typography>
          {data.child.image && (
            <Avatar
              sx={{ padding: "10px", width: "60px", height: "60px" }}
              alt={data.child.title}
              src={data.child.image}
            />
          )}
        </div>

        <hr />
        <Typography variant="body2">• {data.child.description1}</Typography>
        <hr />
        <Typography variant="body2">
          {data.child.description2 && "•"} {data.child.description2}
        </Typography>
      </CardContent>

      {data.child.moreResources && data.child.Urlresources && (
        <CardActions>
          <NavLink to={data.child.Urlresources} target="_blank">
            <Button size="small">Recurso</Button>
          </NavLink>
        </CardActions>
      )}
    </Card>
  );
}
